body {
	background: $color-silver;
	font-size: 16px;
	line-height: 1.4;
	font-family: 'Inerta Regular', Helvetica, Arial, sans-serif;
	color: $color-black; }

.container {
	width: 100%;
	max-width: 1760px;
	padding-left: 40px;
	padding-right: 40px;
	margin: 0 auto;

	@include r(1440) {
		max-width: 1340px; }

	@include r(1024) {
		max-width: 768px;
		padding-left: 29px;
		padding-right: 29px; }

	@include r(575) {
		max-width: 100%;
		padding-left: 20px;
		padding-right: 20px; } }


.header {
	position: absolute;
	width: 100%;
	z-index: 99;

	&__container {
		height: 127px;
		display: flex;
		align-items: center;
		overflow-x: hidden!important;

		@include r(1440) {
			height: 109px; }

		@include r(1024) {
			height: 95px; }

		@include r(575) {
			height: 80px; }

		&::after {
			content: "";
			display: none;
			height: 1px;
			position: absolute;
			left: 40px;
			right: 40px;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.1);

			@include r(1440) {
				display: block; }

			@include r(1024) {
				display: none; } } }

	&__link {
		&:not(:last-child) {
			margin-right: 50px;

			@include r(1440) {
    		margin-right: 46px; }

			@include r(1024) {
				margin-right: 38px; }

			@include r(575) {
				margin-right: 29px; } }

		.icon {
			width: 170px;
			height: 38px;

			&-unicef {
				width: 159px;

				@include r(1440) {
					width: 147px;
					height: auto; }

				@include r(1024) {
					width: 121px; }

				@include r(575) {
					width: 99px; } }

			&-osvitoria {
				width: 183px;

				@include r(1440) {
					width: 166px;
					height: auto; }

				@include r(1024) {
					width: 138px; }

				@include r(575) {
					width: 114px; } } } } }

.hero {
	background-color: $color-yellow;
	padding-top: 171px;
	position: relative;
	z-index: 12;

	@include r(1024) {
		padding-top: 143px; }

	@include r(575) {
		padding-top: 103px; }

	&__container {
		position: relative;
		padding-bottom: 207px;
		z-index: 1;

		@include r(1440) {
			padding-top: 11px;
			padding-bottom: 155px; }

		@include r(1024) {
			padding-top: 0;
			padding-bottom: 144px; }

		@include r(575) {
			padding-bottom: 87px; } }

	&__title {
		font-size: 70px;
		line-height: 91px;
		margin-bottom: 30px;
		color: $color-black;
		font-family: 'Inerta Bold', Helvetica, Arial, sans-serif;
		font-weight: 700;
		text-transform: uppercase;

		@include r(1440) {
			font-size: 50px;
			line-height: 65px;
			margin-bottom: 20px; }

		@include r(1024) {
			font-size: 44px;
			line-height: 57.2px;
			margin-bottom: 10px; }

		@include r(575) {
			font-size: 26px;
			line-height: 33.8px;
			margin-bottom: 10px; } }

	&__subtitle {
		font-size: 22px;
		line-height: 140%;
		max-width: 815px;

		@include r(1440) {
			line-height: 25.2px;
			max-width: 560px;
			font-size: 18px; }

		@include r(1024) {
			font-size: 16px;
			line-height: 22px;
			max-width: 384px; }

		@include r(575) {
			max-width: 313px; } }

	&__switcher {
		position: absolute;
		left: 40px;
		right: 40px;
		bottom: 0;
		transform: translateY(53%);
		height: 192px;
		border-radius: 1000px;
		padding: 10px;
		background-color: $color-white;
		box-shadow: 0px 4px 50px rgba(98, 98, 98, 0.12);
		grid-template-columns: 1fr 1fr;
		display: grid;
		grid-gap: 10px;
		z-index: 2;

		@include r(1440) {
			padding: 8px;
			bottom: 5px;
			height: 144px; }

		@include r(1024) {
			left: 25px;
			right: 25px;
			padding: 5px;
			bottom: 13px;
			height: 94px; }

		@include r(575) {
			left: 15px;
			right: 15px;
			bottom: 2px;
			height: 75px; } }

	&__button {
		background-color: transparent;
		border: 0;
		height: 100%;
		z-index: 3;
		font-family: 'Inerta Bold', Helvetica, Arial, sans-serif;
		font-weight: 700;
		font-size: 36px;
		line-height: 46.8px;
		letter-spacing: 2%;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 1000px;
		transition: color 300ms ease-in, background-color 300ms ease-in;

		@include r(1440) {
			line-height: 39px;
			font-size: 30px; }

		@include r(1024) {
			font-size: 24px {
    	line-height: 31.2px; } }

		@include r(575) {
			font-size: 18px;
			line-height: 25.2px; }

		&:not(.is-active) {
			&:hover {
				background-color: transparentize($color-green, 0.8);
				transition: background-color 300ms ease-out; } }

		&.is-active {
			color: $color-white;
			transition: color 300ms ease-out;

			.icon {
				filter: brightness(5); } }

		&-text {
			max-width: 80%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis; } }

	.icon {
		margin-right: 22px;
		height: 52px;
		width: auto;

		@include r(1440) {
			margin-right: 15px; }

		@include r(1024) {
			margin-right: 11px;
			height: 34px; }

		@include r(575) {
			display: none; } }

	&__slider {
		position: absolute;
		left: 10px;
		top: 10px;
		bottom: 10px;
		border-radius: 1000px;
		background-color: $color-green;
		width: calc(50% - 14px);
		z-index: 2;
		transform: translateX(0);
		transition: transform 300ms ease-out;

		@include r(1024) {
			width: calc(50% - 5px);
			left: 5px;
			top: 5px;
			bottom: 5px; }

		&-right {
			transform: translateX(calc(100% + 7px));
			transition: transform 300ms ease-out;

			@include r(1024) {
				transform: translateX(100%); } } }

	&__balls {
		top: 0;
		position: absolute;
		width: 728px;
		height: 100%;
		overflow: hidden;
		right: 0;
		z-index: 0;

		@include r(1440) {
			width: 626px; }

		@include r(1024) {
			width: 321px; }

		@include r(575) {
			width: 320px; } }

	&__ball {
		position: absolute;
		border-radius: 50%;
		display: inline-block;

		&-pink {
			background-color: $color-pink;
			width: 995px;
			height: 995px;
			top: 446px;

			@include r(1440) {
				width: 852px;
				height: 852px;
				top: 385px; }

			@include r(1024) {
    		top: 322px; }

			@include r(575) {
				top: 297px;
				right: -301px;
				width: 488px;
				height: 488px; } }

		&-green {
			background-color: $color-green;
			width: 680px;
			height: 680px;
			right: -471px;
			top: -147px;

			@include r(1440) {
				width: 582px;
				height: 582px;
				right: -402px;
				top: -127px; }

			@include r(1024) {
				display: none; } }

		&-blue {
			background-color: $color-blue;
			width: 396px;
			height: 396px;
			top: 73px;
			right: 201px;

			@include r(1440) {
				width: 339px;
				height: 339px;
				right: 173px;
				top: 63px; }

			@include r(1024) {
				top: -6px;
				right: -136px; }

			@include r(575) {
				display: none; } }

		&-green-small {
			background-color: $color-green;
			width: 89px;
			height: 89px;
			top: 438px;
			right: 472px;

			@include r(1440) {
				width: 76px;
				height: 76px;
				right: 404px;
				top: 376px; }

			@include r(1024) {
				top: 309px;
				right: 94px; }

			@include r(575) {
				display: none; } } } }

.hero-small {
	background-color: $color-green;
	padding-top: 171px;
	position: relative;

	@include r(1024) {
		padding-top: 143px; }

	@include r(575) {
		padding-top: 103px; }

	&__container {
		position: relative;
		padding-bottom: 120px;
		z-index: 1;

		@include r(1440) {
			padding-top: 11px;
			padding-bottom: 97px; }

		@include r(1024) {
			padding-top: 0;
			padding-bottom: 76px; }

		@include r(575) {
			padding-bottom: 70px; } }

	&__title {
		font-size: 70px;
		line-height: 91px;
		color: $color-black;
		font-family: 'Inerta Bold', Helvetica, Arial, sans-serif;
		font-weight: 700;
		text-transform: uppercase;

		@include r(1440) {
			font-size: 50px;
			line-height: 65px; }

		@include r(1024) {
			font-size: 44px;
			line-height: 57.2px;
			max-width: 700px; }

		@include r(575) {
			font-size: 26px;
			line-height: 33.8px; } }

	&__subtitle {
		font-size: 40px;
		line-height: 52px;
		margin-bottom: 29px;

		@include r(1440) {
			line-height: 39px;
			font-size: 30px;
			margin-bottom: 22px; }

		@include r(1024) {
			font-size: 16px;
			line-height: 22px;
			max-width: 384px; }

		@include r(575) {
			line-height: 28.6px;
			font-size: 22px;
			margin-bottom: 10px; } }

	&__balls {
		top: 0;
		position: absolute;
		width: 673px;
		height: 100%;
		overflow: hidden;
		right: 0;
		z-index: 0;

		@include r(1440) {
			width: 522px; }

		@include r(1024) {
			width: 164px; }

		@include r(575) {
			width: 141px; } }

	&__ball {
		position: absolute;
		border-radius: 50%;
		display: inline-block;

		&-pink {
			background-color: $color-pink;
			width: 680px;
			height: 680px;
			top: -463px;
			right: -472px;

			@include r(1440) {
				top: -317px;
				right: -361px;
				width: 525px;
				height: 525px; }

			@include r(1024) {
    		display: none; } }

		&-yellow {
			background-color: $color-yellow;
			width: 421px;
			height: 421px;
			top: -242px;
			right: 201px;

			@include r(1440) {
				width: 325px;
				height: 325px;
				right: 158px;
				top: -147px; }

			@include r(1024) {
				top: -36px;
				right: -49px;
				width: 188px;
				height: 188px; }

			@include r(575) {
				display: none; } }

		&-blue {
			background-color: $color-blue;
			width: 234px;
			height: 234px;

			@include r(1440) {
				width: 180px;
				height: 180px; }

			@include r(1024) {
				width: 105px;
				height: 105px; }

			&-1 {
				top: 381px;
				right: 444px;

				@include r(1440) {
					top: 336px;
					right: 346px; }

				@include r(1024) {
					right: 51px;
					top: 353px; }

				@include r(575) {
					top: 194px;
					right: 36px; } }

			&-2 {
				top: 178px;
				right: 303px;

				@include r(1440) {
					right: 236px; }

				@include r(1024) {
					right: 61px;
					top: 243px; }

				@include r(575) {
					right: -27px;
					top: 102px; } }

			&-3 {
				top: 113px;
				right: 63px;

				@include r(1440) {
					top: 129px;
					right: 52px; }

				@include r(1024) {
					right: -2px;
					top: 153px; }

				@include r(575) {
					display: none; } }

			&-4 {
				top: 216px;
				right: -162px;

				@include r(1440) {
					top: 208px;
					right: -122px; }

				@include r(1024) {
					display: none; } } } } }

.faq {
	padding-top: 202px;
	padding-bottom: 200px;
	position: relative;
	z-index: 11;
	@include r(1440) {
		padding-top: 148px;
		padding-bottom: 181px; }

	@include r(1024) {
		padding-top: 120px;
		padding-bottom: 119px; }

	@include r(575) {
		padding-top: 80px;
		padding-bottom: 81px; }

	&__container {
		display: flex;

		@include r(1024) {
			flex-direction: column; } }

	&__sidebar {
		position: relative; }

	&__sidebar-title {
		display: none;
		font-size: 25px;
		line-height: 32.5px;
		font-family: 'Inerta Regular', Helvetica, Arial, sans-serif;
		margin-bottom: 19px;

		@include r(1024) {
			display: block; }

		@include r(575) {
			font-size: 22px;
			line-height: 28.6px;
			margin-bottom: 29px; } }

	&__sidebar-button {
		width: 100%;
		height: 89px;
		position: relative;
		border-radius: 1000px;
		background-color: $color-green;
		border: 0;
		display: none;
		padding: 0 55px 0 67px;
		text-align: left;
		overflow: hidden;

		@include r(575) {
			min-height: 68px;
			height: auto;
			padding-bottom: 15px;
			padding-left: 59px;
			padding-top: 15px;
			border-radius: 24px; }

		&.is-active {
			&::before {
				transform: rotate(45deg);
				transition: transform 300ms ease-out; }

			&::after {
				transform: rotate(-45deg);
				transition: transform 300ms ease-out; } }

		&::before,
		&::after {
			content: "";
			display: block;
			width: 12px;
			height: 2px;
			position: absolute;
			background-color: $color-white;
			transition: transform 300ms ease-in;
			z-index: 1; }

		&::before {
			right: 33px;
			top: 43px;
			transform: rotate(-45deg);

			@include r(575) {
				top: 31px; } }

		&::after {
			right: 40px;
			top: 43px;
			transform: rotate(45deg);

			@include r(575) {
				top: 31px; } }

		&-text {
			font-size: 20px;
			line-height: 26px;
			text-transform: uppercase;
			color: $color-white;
			font-family: 'Inerta Bold', Helvetica, Arial, sans-serif;
			font-weight: 700;
			position: relative;
			z-index: 1;

			@include r(575) {
				font-size: 17px;
				line-height: 22.1px; } }

		.icon {
			filter: brightness(5);
			width: 28px;
			height: 28px;
			position: absolute;
			left: 31px;
			top: 29px;
			z-index: 1;

			@include r(575) {
				width: 26px;
				height: auto;
				left: 26px;
				top: 19px; } }

		@include r(1024) {
			display: block; }

		.animated-hover {
			background-color: rgba(255, 255, 255, 0.2); } }

	&__list {
		width: 433px;
		margin-right: 60px;
		padding-top: 80px;

		@include r(1440) {
			padding-top: 50px;
			width: 342px;
			margin-right: 38px; }

		@include r(1024) {
			width: 100%;
			transition: transform 300ms ease-in;
			padding: 0;
			position: absolute;
			background-color: $color-white;
			display: none;
			transform: translateY(-100px);
			margin: 5px auto 0;
			box-shadow: 0px 4px 50px rgba(98, 98, 98, 0.12);
			border-radius: 20px;
			overflow: hidden; }

		&.is-active {
			@include r(1024) {
				display: block;
				z-index: 99;
				transform: translateY(0);
				transition: transform 300ms ease-out; } } }

	&__list-item {
		&:not(:last-child) {
			border-bottom: 1px solid rgba(0, 0, 0, 0.18); } }

	&__list-button {
		display: flex;
		width: 100%;
		padding: 25px 25px 25px 64px;
		background-color: transparent;
		cursor: pointer;
		font-family: 'Inerta Bold', Helvetica, Arial, sans-serif;
		font-weight: 700;
		line-height: 30.8px;
		font-size: 22px;
		text-align: start;
		position: relative;
		overflow: hidden;
		transition: background-color 300ms ease-in;

		@include r(1440) {
			font-size: 18px;
			line-height: 25.2px;
			padding-bottom: 24px;
			padding-left: 59px; }

		@include r(1024) {
			padding-bottom: 27px;
			padding-left: 68px; }

		@include r(575) {
			padding-bottom: 21px;
			padding-top: 21px;
			padding-left: 65px;
			font-size: 16px;
			line-height: 22.4px; }

		&:hover,
		&:focus {
			background-color: transparentize($color-green, 0.9);
			transition: background-color 300ms ease-out;

			@include r(1024) {
				background-color: transparent; } }

		&.is-active {
			background-color: $color-green;
			color: $color-white;
			border-bottom: 0;
			transition: background-color 300ms ease-out;

			.faq__list-icon {
				filter: brightness(5); }

			@include r(1024) {
				color: $color-green;
				background-color: $color-white;

				&:hover,
				&:focus {
					background-color: transparent; }

				.faq__list-icon {
					filter: brightness(1); } } } }

	&__list-icon {
		width: 28px;
		height: auto;
		position: absolute;
		left: 20px;
		top: 26px;

		@include r(1440) {
			left: 19px;
			top: 24px; }

		@include r(1024) {
			left: 32px;
			top: 23px;
			width: 30px;
			height: auto; }

		@include r(575) {
			left: 30px;
			top: 17px;
			width: 28px;
			height: auto; } }

	&__content {
		flex: 1;
		padding: 80px 0 80px 80px;
		border-left: 1px solid rgba(0, 0, 0, 0.18);

		@include r(1440) {
			padding-left: 40px;
			padding-top: 40px;
			padding-bottom: 40px; }

		@include r(1024) {
			padding: 0;
			border: 0; } }

	&__title {
		font-size: 46px;
		font-family: 'Inerta Bold', Helvetica, Arial, sans-serif;
		font-weight: 700;
		line-height: 59.8px;
		margin-bottom: 50px;
		text-transform: uppercase;

		@include r(1440) {
			font-size: 40px;
			line-height: 52px;
			margin-bottom: 40px; }

		@include r(1024) {
			display: none; } }

	&__question {
		overflow: hidden;
		position: relative;
		background-color: $color-white;
		box-shadow: 0px 5.33016px 66.627px rgba(98, 98, 98, 0.12);
		transition: height 300ms ease-out;

		@include r(1024) {
			box-shadow: none;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			background-color: $color-silver; }

		&.is-disable {
			display: none; }

		&.is-active {
			.faq__question-button {
				&::after {
					transform: rotate(180deg);
					transition: transform 300ms ease-out; } } }

		&:not(:last-child) {
			margin-bottom: 20px;

			@include r(1440) {
				margin-bottom: 15px; }

			@include r(1024) {
				margin-bottom: 0; } } }

	&__questions {
		@include r(1024) {
			padding-top: 10px; } }

	&__question-button {
		font-size: 22px;
		font-family: 'Inerta Bold', Helvetica, Arial, sans-serif;
		font-weight: 700;
		padding: 42px 101px 41px 35px;
		line-height: 33px;
		background-color: $color-white;
		width: 100%;
		text-align: start;
		text-transform: uppercase;
		position: relative;
		z-index: 2;
		overflow: hidden;

		@include r(1440) {
			font-size: 16px;
			line-height: 24px;
			padding: 41px 101px 41px 27px; }

		@include r(1024) {
			font-size: 18px;
			line-height: 25.2px;
			padding: 30px 52px 31px 20px;
			text-transform: unset;
			background-color: $color-silver; }

		@include r(575) {
			font-size: 16px;
			line-height: 22.4px;
			padding: 26px 38px 25px 9px; }

		&::before,
		&::after {
			content: "";
			display: block;
			width: 23px;
			height: 2px;
			position: absolute;
			right: 45px;
			top: 56px;
			background-color: $color-green;

			@include r(1440) {
				right: 31px;
				top: 52px; }

			@include r(1024) {
				right: 22px;
				top: 41px; }

			@include r(575) {
				right: 8px;
				top: 34px;
				width: 20px; } }

		&::after {
			transform: rotate(90deg);
			transition: transform 300ms ease-in; }

		&.is-active {
			&::after {
				transform: rotate(180deg);
				transition: transform 300ms ease-out; } }


		&:hover,
		&.is-active {
			.faq__question-circle {
				background-color: transparentize($color-green, 0.8);
				transition: background-color 300ms ease-out; } } }

	&__question-description {
		position: absolute;
		bottom: 0;
		background-color: $color-white;
		padding: 0 35px 35px;
		font-family: 'Inerta Regular', Helvetica, Arial, sans-serif;
		font-size: 22px;
		line-height: 30.8px;

		p {
			&:not(:last-child) {
				margin-bottom: 15px; } }

		a {
			color: $color-green;
			border-radius: 5px;

			&:hover {
				background-color: transparentize($color-green, 0.85); } }

		.download-link {
			border: 2px solid $color-green;
			color: $color-black;
			border-radius: 0;
			height: 74px;
			padding: 0 30px;
			display: inline-flex;
			align-items: center;
			text-decoration: none;
			text-transform: uppercase;
			font-size: 20px;
			font-family: 'Inerta Bold', Helvetica, Arial, sans-serif;
			font-weight: 700;
			position: relative;
			border: none;

			span {
				position: relative;
				z-index: 1;

				@include r(575) {
					font-size: 16px; } }

			&:hover {
				background-color: transparent; } }

		ol {
			list-style-type: auto;
			padding-left: 35px;
			list-style: none;
			&:not(:last-child) {
				margin-bottom: 15px; }

			&:first-child {
				margin-top: 15px; }

			li {
				display: list-item;
				padding-left: 20px;
				min-height: 40px;
				position: relative;
				counter-increment: custom;
				// counter-reset: custom
				&:not(:last-child) {
					margin-bottom: 15px; }

				&::before {
					content: counter(custom) " ";
					// content: ''
					display: flex;
					justify-content: center;
					align-items: center;
					width: 40px;
					height: 40px;
					border-radius: 50%;
					background-color: transparentize($color-green, 0.85);
					position: absolute;
					left: -35px;
					// top: -3px
					top: -8px;
					font-size: 18px;
					line-height: 25; }

				&::marker {
					font-size: 18px; }
				ol li {
					counter-increment: inner;
					counter-reset: custom;
					&::before {
						content: counter(inner) " "; } } } }

		ul {
			list-style-type: auto;
			list-style-type: none;
			padding-left: 30px;

			&:not(:last-child) {
				margin-bottom: 15px; }

			&:first-child {
				margin-top: 15px; }

			li {
				display: list-item;
				padding-left: 25px;
				min-height: 40px;
				position: relative;
				counter-reset: custom;

				&:not(:last-child) {
					margin-bottom: 15px; }

				&::before {
					// content: ''
					content: url('../img/check.svg');

					display: flex;
					width: 40px;
					align-items: center;
					justify-content: center;
					height: 40px;
					border-radius: 50%;
					background-color: transparentize($color-green, 0.85);
					position: absolute;
					left: -30px;
					// top: -5px
					top: -8px; }
				&::marker {
					// content: '✓'
					// color: $color-green
					font-size: 22px; }
				ul {
					margin: 10px 0;
					padding-left: 20px;

					li {
						padding-left: 15px;

						&::before {
							// display: none
							content: url(../img/check.svg);
							display: flex;
							width: 40px;
							align-items: center;
							justify-content: center;
							height: 40px;
							border-radius: 50%;
							position: absolute;
							left: -30px;
							top: -5px;
							background-color: transparent; } } } } }


		@include r(1024) {
			line-height: 27px;
			padding: 0 20px 20px;
			background-color: $color-silver;
			font-size: 18px;
			padding-top: 10px; } }

	&__question-circle {
		position: absolute;
		background-color: transparent;
		transition: background-color 300ms ease-in;
		width: 46px;
		height: 46px;
		border-radius: 50%;
		top: 34px;
		right: 34px;

		@include r(1440) {
			right: 21px;
			top: 30px; }

		@include r(1024) {
			right: 11px;
			top: 19px;
			display: none; }

		@include r(575) {
			right: 0;
			top: 15px;
			width: 38px;
			height: 38px; } }

	&--simple {
		padding-top: 100px;

		@include r(1440) {
			padding-top: 81px; }

		@include r(575) {
			padding-top: 41px; }

		.faq__question {
			height: auto !important; }

		.faq__question-button {
			line-height: 39px;
			padding: 61px 55px 28px 55px;
			font-size: 30px;

			@include r(1440) {
				font-size: 20px;
				line-height: 26px; }

			@include r(1024) {
				text-transform: uppercase;
				padding: 39px 0px 7px; }

			@include r(575) {
				font-size: 17px;
				line-height: 22.1px;
				padding-top: 30px;
				padding-bottom: 18px; }

			&::before,
			&::after {
				display: none; } }

		.faq__question-circle {
			display: none; }

		.faq__question-description {
			padding: 0 60px 80px;
			position: static;

			@include r(1440) {
				font-size: 18px;
				line-height: 25.2px; }

			@include r(1024) {
				line-height: 26px;
				padding: 0 0 40px; }

			@include r(575) {
				padding-bottom: 30px; }

			ol {
				list-style-type: auto;
				padding-left: 35px;

				&:not(:last-child) {
					margin-bottom: 15px; }

				li {
					display: list-item;
					padding-left: 20px;
					min-height: 40px;
					position: relative;

					&::before {
						content: '';
						display: block;
						width: 40px;
						height: 40px;
						border-radius: 50%;
						background-color: transparentize($color-green, 0.85);
						position: absolute;
						left: -35px;
						top: -3px; }

					&::marker {
						font-size: 18px; } } }

			ul {
				list-style-type: auto;
				padding-left: 30px;

				&:not(:last-child) {
					margin-bottom: 15px; }

				li {
					display: list-item;
					padding-left: 25px;
					min-height: 40px;
					position: relative;

					ul {
						margin: 10px 0;
						padding-left: 20px;

						li {
							padding-left: 15px;

							&::before {
								display: none; } } }

					&::before {
						content: '';
						display: block;
						width: 40px;
						height: 40px;
						border-radius: 50%;
						background-color: transparentize($color-green, 0.85);
						position: absolute;
						left: -30px;
						top: -5px; }

					&::marker {
						content: url('../img/check.svg');
						// content: '✓'
						// color: $color-green
						font-size: 22px; } } } }

		.faq__sidebar-button-text {
			font-size: 17px;
			line-height: 22.1px; }

		.faq__sidebar-button {
			height: 74px;

			@include r(575) {
				height: auto;
				min-height: 74px;
				padding-top: 15px;
				padding-bottom: 15px;
				padding-right: 60px;
				border-radius: 24px; }

			.icon {
				top: 13px;
				left: 27px;
				height: 25px;
				width: 25px; }

			&::after,
			&::before {
				top: 37px; } }

		.faq__sidebar-title {
			@include r(575) {
				margin-bottom: 15px; } } } }

.posts {
	padding: 225px 0 145px 0;
	position: relative;
	overflow: hidden;

	@include r(1440) {
		padding: 189px 0 191px 0; }

	@include r(1024) {
		padding: 130px 0 120px 0; }

	@include r(575) {
		padding: 80px 0 80px 0; }

	&__title {
		font-size: 66px;
		line-height: 85.8px;
		font-family: 'Inerta Bold', Helvetica, Arial, sans-serif;
		font-weight: 700;
		margin-bottom: 100px;
		text-transform: uppercase;
		max-width: 85%;

		@include r(1440) {
			font-size: 52px;
			line-height: 67.6px;
			margin-bottom: 91px; }

		@include r(1024) {
		  margin-bottom: 88px;
		  line-height: 52px;
		  font-size: 40px; }

		@include r(775) {
			font-size: 32px;
			line-height: 48px; }

		@include r(575) {
			font-size: 30px;
			line-height: 39px;
			margin-bottom: 40px;
			max-width: 100%; } }

	&__background {
		background-color: $color-green;
		position: absolute;
		height: 901px;
		left: 0;
		right: 0;
		top: 0;
		z-index: 0;
		overflow: hidden;

		@include r(1440) {
			height: 723px; }

		@include r(1024) {
			height: 571px; }

		@include r(575) {
			height: 499px; } }

	&__circle {
		border-radius: 50%;
		width: 1754px;
		position: absolute;
		height: 1754px;

		@include r(1440) {
			height: 1508px;
			width: 1508px; }

		&-blue {
			background-color: $color-blue;
			left: -933px;
			top: -1075px;
			z-index: 1;

			@include r(1440) {
				left: -935px;
				top: -946px; }

			@include r(1024) {
				top: -1008px;
				left: -1072px; }

			@include r(575) {
				display: none; } }

		&-yellow {
			background-color: $color-yellow;
			right: -741px;
			top: -2px;

			@include r(1440) {
				right: -847px;
				top: 0px; }

			@include r(1024) {
				right: -578px;
				top: 14%; }

			@include r(575) {
				left: -153px;
				top: 89px; } } }

	&__container {
		position: relative;
		z-index: 1;

		@include r(575) {
			padding: 0 30px; } }

	&__slider {
		margin-right: calc(78% - 100vw);
		box-shadow: 0 4px 50px rgba(98, 98, 98, 0.12);

		@include r(1440) {
			margin-right: calc(80% - 100vw); }

		@include r(1024) {
			margin-right: calc(71.5% - 100vw); }

		@include r(575) {
			margin-right: calc(15.9% - 100vw); } }

	&__card {
		padding: 10px 10px 40px 10px;
		background-color: $color-white;
		box-shadow: 0px 4px 50px rgba(98, 98, 98, 0.12);
		transition: box-shadow 300ms ease-out;
		@include r(1440) {
			padding: 5px 5px 30px 5px; }

		@include r(1024) {
			padding: 5px 5px 20px; }

		&:hover,
		&:focus {
			box-shadow: 0px 4px 60px rgba(98, 98, 98, 0.3);

			.posts__card-image {
				transform: scale(1.2);
				transition: transform 300ms ease-out; } }

		&-header {
			height: 21.3vw;
			margin-bottom: 37px;

			@include r(1440) {
				height: 21vw;
				margin-bottom: 35px; }

			@include r(1024) {
				height: 30vw;
				margin-bottom: 26px; }

			@include r(575) {
				height: 62vw; } }

		&-image-wrapper {
			max-height: 100%;
			overflow: hidden;
			height: 100%; }

		&-image {
			display: block;
			object-fit: cover;
			width: 100%;
			height: 100%;
			transform: scale(1);
			transition: transform 300ms ease-in; }

		&-title {
			font-size: 30px;
			line-height: 39px;
			font-family: 'Inerta Bold', Helvetica, Arial, sans-serif;
			font-weight: 700;
			margin-bottom: 29px;
			padding: 0 30px;
			height: 156px;
			text-transform: uppercase;
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
			overflow: hidden;

			@include r(1440) {
				font-size: 20px;
				line-height: 26px;
				padding: 0 25px;
				height: 79px;
				-webkit-line-clamp: 3;
				margin-bottom: 30px; }

			@include r(1024) {
				font-size: 17px;
				line-height: 22.1px;
				padding: 0 16px;
				height: 89px;
				margin-bottom: 13px;
				-webkit-line-clamp: 4; } }

		&-button {
			height: 63px;
			padding: 0 28px 0 28px;
			background-color: $color-white;
			border: 2px solid $color-green;
			margin-left: 30px;
			margin-left: 32px;
			margin-bottom: 2px;
			line-height: 24px;
			font-size: 16px;
			text-transform: uppercase;
			font-family: 'Inerta Bold', Helvetica, Arial, sans-serif;
			font-weight: 700;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			color: inherit;
			text-decoration: none;
			width: fit-content;
			cursor: pointer;
			border: none;
			outline: 2px solid $color-green;

			@include r(1440) {
				margin-left: 25px;
				margin-left: 27px;
				height: 64px; }

			@include r(1024) {
				margin-left: 16px;
				margin-left: 18px;
				height: 60px;
				margin-right: 14px;
				width: -webkit-fill-available; } }

		&-button-text {
			position: relative;
			z-index: 1; }

		&-icon {
			position: relative;
			z-index: 1;
			width: 13px;
			height: 20px;
			display: inline-block;
			margin-left: 10px;

			&::before,
			&::after {
				// content: ""
				display: block;
				position: absolute;
 }				// border-top: 2px solid $color-black

			&::before {
				content: '';
				width: 20px;
				height: 20px;
				right: 0px;
				transform: rotate(0deg);
				width: 15px;
				background: url('../img/arrow-btn.svg') no-repeat center; }


			&::after {
				border-right: 2px solid $color-black;
				height: 10px;
				width: 10px;
				right: 0px;
				top: 4px; } } }

	&__arrow {
		width: 90px;
		height: 90px;
		background-color: $color-white;
		top: 244px;
		right: 90px;
		border-radius: 50%;
		transition: transform 300ms ease-in;

		&:hover,
		&:focus {
			transform: scale(1.2);
			transition: transform 300ms ease-out;

			&::before,
			&::after {
				border-color: $color-green; }
			&::before {
				background-image: url('../img/arrow-green.svg'); } }

		@include r(1440) {
			width: 70px;
			height: 70px;
			top: 211px; }

		@include r(1024) {
			top: 141px;
			right: 30px; }

		@include r(575) {
			display: none; }

		&--prev {
			left: unset;
			right: 200px;
			transform: rotate(180deg);

			&:hover,
			&:focus {
				transform: scale(1.2) rotate(180deg);
				transition: transform 300ms ease-out; }

			@include r(1440) {
				right: 180px; }

			@include r(1024) {
				right: 120px; } }

		&::before,
		&::after {
			content: '';
			display: block;
			width: 23px;
			position: absolute;
 }			// border-top: 2px solid $color-black

		&::before {
			right: 35px;
			top: 50%;
			margin-top: -1px;
			width: 20px;

			background: url('../img/arrow.svg') no-repeat center;
			width: 30px;
			height: 20px;
			top: auto;
			right: 50%;
			transform: translateX(50%);
			@include r(1440) {
 } }				// right: 24px

		&::after {
			// border-right: 2px solid $color-black
			height: 15px;
			width: 15px;
			right: 36px;
			top: 38px;
			// transform: rotate(45deg)

			@include r(1440) {
				// right: 25px
 } } } }				// top: 28px

.policy {
	padding: 120px 0 50px 0;

	&__title {
		font-size: 50px;
		font-family: 'Inerta Bold', Helvetica, Arial, sans-serif;
		font-weight: 700;
		line-height: 1.3;
		margin-bottom: 50px;
		text-transform: uppercase; }

	&__content p {
		font-size: 18px;
		font-family: 'Inerta Regular', Helvetica, Arial, sans-serif;
		line-height: 26px;
		margin-bottom: 15px; } }

.footer {
	background-color: $color-green;
	padding: 160px 0 60px 0;
	position: relative;
	overflow: hidden;

	@include r(1440) {
		padding: 99px 0 38px 0; }

	@include r(1024) {
		padding: 122px 0 21px 0; }

	@include r(575) {
		padding: 77px 0 17px; }

	&__container {
		display: flex;
		justify-content: space-between;
		margin-bottom: 73px;

		@include r(1440) {
			margin-bottom: 85px; }

		@include r(1024) {
			flex-wrap: wrap;
			margin-bottom: 90px; }

		@include r(575) {
			margin-bottom: 60px;
			padding: 0 30px;
			flex-direction: column; } }

	&__links {
		display: flex;
		flex-direction: column;
		max-width: 394px;

		@include r(1024) {
			width: 51%;
			max-width: unset;
			order: 2; }

		@include r(575) {
			width: 100%; } }

	&__title {
		font-family: 'Inerta Regular', Helvetica, Arial, sans-serif;
		font-size: 22px;
		line-height: 30.8px;
		margin-bottom: 30px;

		@include r(1440) {
			font-size: 18px;
			line-height: 25.2px;
			margin-bottom: 39px; }

		@include r(1024) {
			margin-bottom: 28px; }

		@include r(575) {
			margin-bottom: 9px;
			font-size: 16px;
			line-height: 22.4px; } }

	&__link {
		font-family: 'Inerta Regular', Helvetica, Arial, sans-serif;
		font-size: 30px;
		line-height: 39px;
		color: $color-black;
		text-decoration: none;
		transition: color 300ms ease-in;
		max-width: max-content;

		@include r(1440) {
			font-size: 26px;
			line-height: 36.4px; }

		@include r(575) {
			font-size: 22px;
			line-height: 28.6px; }

		&:hover,
		&:focus {
			color: $color-white;
			transition: color 300ms ease-out; }

		&:not(:last-child) {
			margin-bottom: 30px;

			@include r(1440) {
				margin-bottom: 15px; }

			@include r(1024) {
				margin-bottom: 10px; }

			@include r(575) {
				margin-bottom: 9px; } } }

	&__logos {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-left: -26px;

		@include r(1024) {
			order: 1;
			margin: 0;
			align-items: flex-start; }

		@include r(575) {
			margin-bottom: 34px;
			align-items: flex-start; }

		.footer__link {
			&:first-child {
				padding-left: 8px;

				@include r(1024) {
					padding-left: 0; }

				.icon {
					width: 283px;
					height: 70px;

					@include r(1440) {
						width: 213px;
						height: auto; }

					@include r(1024) {
						width: 183px; }

					@include r(575) {
						width: 132px;
						line-height: 38px; } } } }

		.icon {
			width: 307px;
			height: 66px;

			@include r(1440) {
				width: 220px;
				height: auto; }

			@include r(1024) {
				width: 180px; }

			@include r(575) {
				width: 130px; } }

		.icon-osvitoria {
			@include r(1024) {
				position: relative;
				top: 5px; }

			@include r(575) {
				top: 0; } } }

	&__socials {
		display: flex;
		flex-direction: column;
		max-width: 365px;

		@include r(1440) {
		  max-width: 238px; }

		@include r(1024) {
			max-width: unset;
			margin: 0 auto;
			flex-direction: revert;
			justify-content: space-between;
			width: 100%;
			border-top: 1px solid rgba(0, 0, 0, 0.1);
			padding-top: 39px;
			margin-top: 46px;
			order: 3;
			align-items: center; }

		@include r(575) {
			align-items: flex-start;
			flex-direction: column;
			margin: 37px auto 0;
			padding-top: 41px; }

		.footer__title {
			margin-bottom: 40px;

			@include r(1440) {
				margin-bottom: 33px; }

			@include r(1024) {
				display: none; }

			@include r(575) {
				display: block;
				font-size: 16px;
				line-height: 22.4px;
				margin-bottom: 19px; } } }

	&__socials-wrapper {
		display: flex;
		margin-bottom: 40px;

		@include r(1440) {
			margin-bottom: 32px; }

		@include r(1024) {
			margin-bottom: 0;
			margin-right: 25px; }

		@include r(575) {
			margin-bottom: 39px; } }

	&__social {
		height: 80px;
		width: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 50%;
		position: relative;
		overflow: hidden;

		@include r(1440) {
			height: 48px;
			width: 48px; }

		@include r(1024) {
			height: 58px;
			width: 58px; }

		@include r(575) {
			height: 48px;
			width: 48px; }

		&::before {
			content: "";
			display: block;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			position: absolute;
			background-color: $color-white;
			border-radius: 50%;
			transform: translateY(80px);
			transition: transform 300ms ease-in; }

		&:not(:last-child) {
			margin-right: 15px; }

		&:hover,
		&:focus {
			.icon {
				filter: brightness(1);
				transition: filter 300ms ease-out; }

			&::before {
				transform: translateY(0px);
				transition: transform 300ms ease-in; } }

		.icon {
			width: 25px;
			height: 25px;
			filter: brightness(0);
			transition: filter 300ms ease-in;

			@include r(1440) {
				width: 20px;
				height: 20px; } } }

	&__copyright {
		font-family: 'Inerta Regular', Helvetica, Arial, sans-serif;
		font-size: 22px;
		line-height: 30.8px;

		@include r(1440) {
			font-size: 18px;
			line-height: 25.2px; } }

	&__bottom {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 79px;
		position: relative;
		z-index: 1;

		@include r(1440) {
			padding-top: 39px; }

		@include r(575) {
			padding: 22px 30px 0 30px;
			align-items: flex-start; }

		.footer__title {
			margin-bottom: 23px;

			@include r(1440) {
				margin-bottom: 10px; }

			@include r(1024) {
				font-size: 16px;
				line-height: 22.4px;
				margin-bottom: 5px; } } }

	&__circle {
		position: absolute;
		background-color: $color-yellow;
		width: 4410px;
		margin: 0 auto;
		border-radius: 50%;
		height: 4410px;
		left: calc((4410px - 100vw) / -2);
		// bottom: calc(223px - 4410px)
		bottom: calc(223px - 4360px);

		@include r(1440) {
			// bottom: calc(148px - 4410px)
			bottom: calc(148px - 4350px); }

		@include r(1024) {
			// bottom: calc(164px - 1493px)
			bottom: calc(164px - 1460px);
			left: calc((1493px - 100vw)/-2);
			height: 1493px;
			width: 1493px; }

		@include r(575) {
			bottom: calc(146px - 1493px); }
		@include r(575) {
			bottom: calc(146px - 1460px); } }

	&__bottom-link {
		margin-top: 15px;
		span {
			margin-left: 5px;
			margin-right: 8px; } }
	&__company {
		font-family: 'Inerta Regular', Helvetica, Arial, sans-serif;
		font-size: 1em;
		line-height: 1.4em;
		color: $color-black;
		position: relative;
		// height: 45px
		display: flex;
		// width: 45px
		text-decoration: none;
		margin-left: 10px;

		&-logo {
			// position: absolute
			// height: 100%
			// width: 100%
			top: 0px;
			left: 0px;
			// border-radius: 5px
			line-height: 1;
			@include r(1440) {
				width: 90px;
				height: 17px; } } }

	&__bottom-link {
		display: inline-flex;
		align-items: center;
		font-family: 'Inerta Regular', Helvetica, Arial, sans-serif;
		font-size: 1em;
		line-height: 1.4em;
		color: $color-black; } }

.error {
	padding-top: 218px;
	min-height: 880px;
	background-color: $color-yellow;
	position: relative;
	overflow: hidden;
	height: 100vh;

	@include r(1440) {
		height: 100vh;
		min-height: 670px;
		padding-top: 146px; }

	@include r(1024) {
		height: 816px;
		min-height: 100vh;
		padding-top: 126px; }

	@include r(625) {
		height: 100vh;
		min-height: 600px;
		padding-top: 96px; }

	&__container {
		position: relative;
		z-index: 1; }

	&__title {
		font-size: 34px;
		font-family: 'Inerta Bold', Helvetica, Arial, sans-serif;
		font-weight: 700;
		line-height: 1.4;
		margin-bottom: 40px;
		text-transform: uppercase;
		max-width: 400px;

		@include r(1024) {
			font-size: 26px; }

		@include r(625) {
			font-size: 22px;
			margin-bottom: 20px; } }

	&__tablet {
		display: none;
		width: 476px;
		position: absolute;
		right: -183px;
		top: -51px;

		svg {
			display: block;
			width: 100%;
			height: auto; }

		@include r(1024) {
			display: block; }

		@include r(625) {
			display: none; } }

	&__link {
		font-size: 20px;
		font-family: 'Inerta Bold', Helvetica, Arial, sans-serif;
		font-weight: 700;
		line-height: 1.5;
		padding: 0 40px;
		height: 80px;
		background-color: $color-green;
		position: relative;
		display: inline-flex;
		align-items: center;
		color: $color-black;
		text-decoration: none;
		overflow: hidden;
		text-transform: uppercase;

		@include r(1440) {
			padding-left: 30px;
			padding-right: 30px;
			height: 64px; }

		@include r(1024);


		&-text {
			position: relative;
			z-index: 1; }

		&::before {
			content: "";
			display: block;
			height: 240px;
			left: -25px;
			right: -25px;
			top: 0;
			position: absolute;
			background-color: $color-white;
			border-radius: 50%;
			z-index: 0;
			transform: translateY(80px);
			transition: transform 300ms ease-in; }

		&:hover,
		&:focus {
			&::before {
				transform: translateY(-50px);
				transition: transform 300ms ease-in; } } }

	&__label {
		border-radius: 60px;
		background: $color-blue;
		font-size: 32px;
		line-height: 1.3;
		color: $color-black;
		font-family: 'Inerta Regular', Helvetica, Arial, sans-serif;
		padding: 20px 35px;
		margin-left: -30px;
		margin-bottom: 40px;
		display: inline-block;
		vertical-align: top;
		transform: rotate(10deg);

		@include r(1440) {
			padding: 15px 30px;
			margin-bottom: 46px;
			font-size: 24px; }

		@include r(1024) {
			padding: 15px 30px;
			margin-left: 0;
			margin-bottom: 40px;
			font-size: 24px; }

		@include r(625) {
			padding: 10px 20px;
			margin-left: -7px;
			margin-bottom: 21px;
			font-size: 18px;
			transform: rotate(6.77deg); } }

	&__image {
		right: 0;
		top: 0;
		width: 100%;
		height: 100%;
		position: absolute; }

	&__svg {
		display: block;
		height: auto;
		width: 79.7916%;
		max-width: 1532px;
		margin-top: 163px;
		margin-left: auto;
		margin-right: 0;

		@include r(1024) {
			display: block;
			height: auto;
			width: 721px;
			max-width: 100%;
			margin-top: 423px;
			margin-left: auto;
			margin-right: 0; }

		@include r(625) {
			display: block;
			height: auto;
			width: 128.5%;
			max-width: none;
			margin-top: 377px;
			margin-left: auto;
			margin-right: 0;
			position: absolute;
			right: 0;
			top: 0; } } }

.hover-button {
	overflow: hidden;
	border: none;
	outline: 2px solid $color-green;
	margin-bottom: 2px;
	margin-top: 2px;


	&::after {
		content: '';
		border-radius: 50%;
		width: 100px;
		height: 100px;
		background: $color-green;
		position: absolute;
		left: 50%;
		top: 100%;
		margin-top: 100px;
		transform: translate(-50%,-50%);
		transition: .3s; }



	&:hover,
	&:focus {
		&::after {
			top: 50%;
			width: 150%;
			height: 150%;
			margin-top: 0;

			right: -5px;
			display: block;
			border: none;
			zoom: 2; } } }


.animated-hover {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	z-index: 0;
	background-color: rgba(65, 168, 62, 0.1);
	width: 0px;
	height: 0px;
	display: none;
	transition: width 300ms ease-in, height 300ms ease-in;

	@include r(1024) {
		display: block; } }

.round-number {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-color: transparentize($color-green, 0.85);
	color: $color-black;
	margin-right: 15px;
	width: 40px;
	min-width: 40px;
	height: 40px;
	font-size: 18px; }

.round-checkmark {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-color: transparentize($color-green, 0.85);
	color: $color-black;
	margin-right: 15px;
	width: 40px;
	min-width: 40px;
	height: 40px;
	font-size: 18px;
	position: relative;

	&:before {
		content: url('../img/check.svg');
		// content: '✓'
 } }		// color: $color-green

.round-checkmark-no-color {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-color: transparent;
	color: $color-black;
	margin-right: 5px;
	width: 40px;
	min-width: 40px;
	height: 40px;
	font-size: 18px;
	position: relative;

	&:before {
		content: url('../img/check.svg');
		// content: '✓'
 } }		// color: $color-green

ol + .hover-button,
ul + .hover-button {
	margin-top: 20px; }

.faq--simple .faq__sidebar-button .icon {
	top: 21px; }

.faq__question-description ul li > ul li {
	&::before {
		content: url(../img/check.svg);
		display: flex;
		width: 40px;
		align-items: center;
		justify-content: center;
		height: 40px;
		border-radius: 50%;
		position: absolute;
		left: -30px;
		top: -5px;
		background-color: transparent; } }

@media screen and (min-width: 1920px) {
	.error404 .container,
	.error .container {
		max-width: 100%;
		padding-left: 70px;
		padding-right: 70px; } }

@media only screen and (max-width: 575px) {
	.header .header__link .icon-osvitoria,
	.header .header__link .icon-unicef {
 		width: 100%; }
	.posts__card {
		transition: all .3s; }
	.posts__card-button,
	.faq__question-description .download-link {
		border: unset;
		outline: 2px solid #41a83e;
		text-align: center; }
	.faq__question-description .download-link {
		margin-left: 2px;
		margin-right: 2px; }
	.posts__card-image-wrapper {
		height: 100%; } }

@media only screen and (max-width: 1024px) {
	.faq--simple .faq__sidebar-button .icon {
		top: 22pxgu; }
	.faq__question-description .download-link {
		margin-left: 2px;
		margin-right: 2px; } }
