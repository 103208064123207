.icon-check {
  width: 1.23em;
  height: 1em;
  // fill: initial;
}
.icon-facebook-colored {
  width: 0.5em;
  height: 1em;
  // fill: #37B2F3;
}
.icon-facebook {
  width: 0.53em;
  height: 1em;
  // fill: #000;
}
.icon-icon-1 {
  width: 1em;
  height: 1em;
  // fill: #4BAADE;
}
.icon-icon-10 {
  width: 1em;
  height: 1em;
  // fill: #FABE30;
}
.icon-icon-11 {
  width: 1em;
  height: 1em;
  // fill: #FABE30;
}
.icon-icon-12 {
  width: 1.07em;
  height: 1em;
  // fill: #41A83E;
}
.icon-icon-2 {
  width: 1.07em;
  height: 1em;
  // fill: #FABE30;
}
.icon-icon-3 {
  width: 1em;
  height: 1em;
  // fill: #41A83E;
}
.icon-icon-4 {
  width: 1em;
  height: 1em;
  // fill: #FABE30;
}
.icon-icon-5 {
  width: 1.07em;
  height: 1em;
  // fill: #4BAADE;
}
.icon-icon-6 {
  width: 1.19em;
  height: 1em;
  // fill: #FEBBBC;
}
.icon-icon-7 {
  width: 1em;
  height: 1em;
  // fill: #FABE30;
}
.icon-icon-8 {
  width: 1em;
  height: 1em;
  // fill: #41A83E;
}
.icon-icon-9 {
  width: 1em;
  height: 1em;
  // fill: #4BAADE;
}
.icon-instagram-colored {
  width: 1em;
  height: 1em;
  // fill: #FF74BF;
}
.icon-instagram {
  width: 1em;
  height: 1em;
  // fill: #000;
}
.icon-osvitoria {
  width: 4.82em;
  height: 1em;
  // fill: #000;
}
.icon-unicef {
  width: 4.18em;
  height: 1em;
  // fill: #000;
}
.icon-viber-colored {
  width: 1em;
  height: 1em;
  // fill: #BA5CFD;
}
.icon-viber {
  width: 0.96em;
  height: 1em;
  // fill: #000;
}
.icon-youtube-colored {
  width: 1.33em;
  height: 1em;
  // fill: #E13A3A;
}
.icon-youtube {
  width: 1.37em;
  height: 1em;
  // fill: #000;
}
